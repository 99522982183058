import { INavData } from '@coreui/angular';

export const navItems: INavData[ ] = [
  {
    name: 'Home',
    class: 'unique',
    url: '/home'

  },
  {
    name: 'My Queue',
    url: '/myqueue'
  },
  {
    name: 'Stats',
    url: '/stats'
  },
  {
    name: 'Reports',
    url: '/reports'
  },
];
