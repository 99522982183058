import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';



export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },

  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [

      {
        path: 'home',
        loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
      }

    ]
  },

    {
        path: '',
        component: DefaultLayoutComponent,
        data: {
            title: 'My Queue'
        },
        children: [

            {
                path: 'myqueue',
                loadChildren: () => import('./views/myqueue/myqueue.module').then(m => m.MyqueueModule)
            }

        ]
    },

    {
        path: '',
        component: DefaultLayoutComponent,
        data: {
            title: 'Stats'
        },
        children: [

            {
                path: 'stats',
                loadChildren: () => import('./views/stats/stats.module').then(m => m.StatsModule)
            }

        ]
    },

    {
        path: '',
        component: DefaultLayoutComponent,
        data: {
            title: 'Reports'
        },
        children: [

            {
                path: 'reports',
                loadChildren: () => import('./views/reports/reports.module').then(m => m.ReportsModule)
            }

        ]
    }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
